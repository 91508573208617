import IndexedDBManager from '../DB/indexDB';
import configuration from "../../package.json"

const { version, indexedDB } = configuration;

export class SoundPlayer {
    constructor(sound, dispatch, setSoundName, setPlay, setPlayIcon, params, loadSound, startSound, endSound) {
        this.dbManager = new IndexedDBManager(indexedDB, "sounds", version);
        this.loadSound = loadSound;
        this.startSound = startSound;
        this.endSound = endSound;
        this.sound = sound
        this.dispatch = dispatch
        this.setSoundName = setSoundName
        this.setPlay = setPlay
        this.setPlayIcon = setPlayIcon
        this.params = params
    }
    async playSound() {
        if(this.sound  instanceof Object){
            let list = []
             this.sound.map(async (item,index)=>{
                 await this.dbManager.openDatabase();
                 const result = await this.dbManager.getData(item);
                 if(result && result instanceof Object != true){
                    list.push({sound: result, base64: true})
                  }else{
                    list.push({sound: item, base64: false})
                  }
             })
             let soundList = {
                isList: true,
                items: list,
                app: this.params.app,
                grade: this.params.grade,
                action: [this.loadSound, this.startSound, this.endSound]
              }
              this.dispatch(this.setPlay(soundList));
        }else{
            this.dispatch(this.setSoundName(this.sound));
            await this.dbManager.openDatabase();
            const result = await this.dbManager.getData(this.sound);
            if (result && result instanceof Object != true) {
                this.dispatch(this.setPlay({
                    sound: result,
                    base64: true,
                    app: this.params.app,
                    grade: this.params.grade,
                    action: [this.loadSound, this.startSound, this.endSound]
                }));
            } else {
                this.dispatch(this.setPlay({
                    sound: this.sound,
                    base64: false,
                    app: this.params.app,
                    grade: this.params.grade,
                    action: [this.loadSound, this.startSound, this.endSound]
                }));
            }
        }


    }
}