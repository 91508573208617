import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import IndexedDBManager from "./DB/indexDB";
import configure from "../package.json";

function App(props) {
  const params = useParams()
  const {homepage, version, indexedDB} = configure
  const [startAnimation, setStartAnimation] = useState(false)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true)
  const [startSpin, setStartSpin] = useState(false)

  useEffect(() => {
    LoadImages()
    setTimeout(() => {
      setStartAnimation(true)
      setTimeout(() => {
        installData()
      }, 500);
    }, 500);

  }, [])

  const installData = async () => {
    setStartSpin(true)
    // installData()
    setTimeout(() => {
      setStartSpin(false)
      const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
      if (path) {
        navigate.replace(path);
      }
    }, 1000);
    setTimeout(() => {
      navigate(`/home`)
    }, 2000);
  }

  const LoadImages = () => {
    const dbManager = new IndexedDBManager(indexedDB, "images", version);
    [
      homepage+"images/4ictcover.svg",
      homepage+"images/5ictcover.svg",
      homepage+"images/6ictcover.svg",
    ].map(image=>convertAudioToBase64(image).then(async (base64Data) => {
      await dbManager
        .openDatabase()
        .then(async () => {
          await dbManager
            .addData({ id: image, data: base64Data })
            .then(async (result) => { })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }))
  }



  function convertAudioToBase64(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }


  return (
    <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2  overflow-hidden md:max-w-[875px] md:m-auto relative justify-center items-center">
      <div className={`flex w-full h-fit justify-center flex-col gap-6  items-center`}>
        <div className="relative w-[200px] h-[200px]">
          {
            (startSpin === true) ?
              <div className="h-[130px] w-[130px] absolute m-auto border-[10px] border-r-inherit z-0 top-[10px] left-[35px] animate-spin border-b-inherit border-company-red rounded-full" />
              : <></>
          }
          <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-100 z-10 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="GPS education" src="/bg/GPS-Logo.svg" />
        </div>
        <div className="h-5"></div>
        <div className="h-5"></div>
        <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-500 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="المعاصر" src="/bg/logo_1.svg" />
        <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-700 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 -mt-0'}`} alt="الإمتحان" src="/bg/logo_2.svg" />
        <img className={`h-auto w-[200px] block ease-in-in duration-500 delay-1000 ${(startAnimation === false) ? 'opacity-0 -mt-10' : 'opacity-1 mt-3'}`} alt='el-moasser' src="/bg/logo_3.svg" />
      </div>
    </div>
  )
}



export default App;
