import React, { useEffect, useState } from 'react'
import axios from "axios"
import jsyaml from "js-yaml";
import { Link, useParams } from "react-router-dom";
import { NavBar, LoadingScreen, Footer, NotFound, AnalysisUser } from "../../components"
import IndexedDBManager from "../../DB/indexDB";
import confguration from "../../../package.json"

export const Home = (props) => {
    const params = useParams()
    const {homepage, version, indexedDB} = confguration
    const [partsList, setPartsList] = useState([])
    const [loading, setLoading] = useState(true)
    const [cover, setCover] = useState(undefined)
    const [NotfoundData, setNotfoundData] = useState(false)
    const [main, setMain] = useState([])
    const [file, setFile] = useState(null)
    useEffect(() => {
        loadYaml()
    }, [params])

    console.log(partsList)

    const constGetLocalImage = async (id) => {
        let image = homepage+"images/" + id
        const dbManager = new IndexedDBManager(indexedDB, "images", version);
        await dbManager.openDatabase().then(async () => {
            await dbManager.getData(image).then(result => {
                if (result) {
                    setCover(image)
                } else {
                    LoadImages(image)
                    setCover(image)
                }
            })
        })
        return image
    }

    const LoadImages = (image) => {
        const dbManager = new IndexedDBManager(indexedDB, "images", version);
        [image].map(image => convertAudioToBase64(image).then(async (base64Data) => {
            await dbManager
                .openDatabase()
                .then(async () => {
                    await dbManager
                        .addData({ id: image, data: base64Data })
                        .then(async (result) => { })
                        .catch((e) => {
                        });
                })
                .catch((error) => {
                    setCover(image)
                });
        }))
    }

    function convertAudioToBase64(url) {
        return fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsDataURL(blob);
            });
          });
      }

    const loadYaml = () => {
        axios.get(`${homepage}data/manifest.yaml`).then((res) => {
            const { manifest } = jsyaml.load(res.data);
            const curentData = manifest.items.filter(item => item.subject == params.subject && item.grade == params.grade && item.content == "dictionary")
            if (curentData.length > 0) {
                setMain({
                    year: params.year ? params.year : curentData[curentData.length - 1].year,
                    version: params.version ? params.version : curentData[curentData.length - 1].version
                })
                setFile(`${params.version ? 'v'.params.version : curentData[curentData.length - 1].yaml}.yaml`)
                axios.get(`${homepage}data/${params.version ? 'v'.params.version : curentData[curentData.length - 1].yaml}.yaml`).then((res) => {
                    const { parts, metadata } = jsyaml.load(res.data);
                    constGetLocalImage(metadata.cover_image)
                    setPartsList(parts)
                }).catch((error) => {
                    console.log(error);
                })
            } else {
                setNotfoundData(true)
            }
        }).catch((error) => {
            console.log(error)
        }).finally(() => {
            setLoading(false)
        })
    }




    const PageRender = ({ index, title, kind, items, sections, header, images }) => {
        return (<>
            <Link to={`/dict/${params.subject}/${params.grade}/${main.year}/${main.version}/${kind}`} hash={title} state={{ title, kind, items, sections, header, images }}
                className="flex w-full flex-row gap-[2px]">
                <div className="flex bg-company-red w-14 whitespace-nowrap leading-[30px] text-center text-white p-1 border rounded-l-lg text-lg font-semibold items-center justify-center">{index}</div>
                <div className="flex bg-white text-gray-700 w-full leading-[30px] p-1 border rounded-r-lg text-lg font-semibold items-center justify-start">{title.phrase}</div>
            </Link>
        </>
        )
    }

    return (
        <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2  overflow-hidden md:max-w-[875px] md:m-auto relative">
            {file?<AnalysisUser file={file} app={'dict'}  />:<></>}
            <NavBar logo="logo_3.svg" home={false} grade="3" extention="rd" main="Prep." search={true} />
            <LoadingScreen active={loading} />
            <div className="flex flex-col w-full top-14 sm:top-16 relative">
                {
                    (cover != null) ? <img alt="book cover" src={`${cover}`}  className='h-auto bg-white w-full pt-4' /> : <></>
                }
                {
                    (NotfoundData) ? <NotFound /> : <></>
                }
                <div className="p-2 gap-2 flex sm:grid flex-col mt-3">
                    {
                        partsList.map((part, index) => <PageRender key={index} index={index+1} {...part} />)
                    }
                </div>
            </div>
            <div className="h-24" />
            <Footer />
        </div>
    );
}
