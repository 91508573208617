import { useEffect } from 'react'
import configuration from "../../package.json"
import { v4 as uid4 } from 'uuid';
import AWS from 'aws-sdk';

export const AnalysisPDF = (props) => {
    const { app, file } = props
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ID,
        secretAccessKey: process.env.REACT_APP_AWS_KEY,
        region: "eu-west-1",
    });
    const documentClient = new AWS.DynamoDB.DocumentClient();

    useEffect(() => {
        console.log(true);
        if(configuration.analysis){
            const user = localStorage.getItem('user')
            if (!user) {
                createUser()
            } else {
                updateUser()
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createUser = async () => {
        const id = uid4();
        const user = {
            uuid: id,
            count: 1,
            last_access: Date.now(),
            apps: {
                [app]: {
                    [file]: [
                        Date.now(), 1
                    ]
                }
            }
        }
        localStorage.setItem('user', JSON.stringify(user))
        const dataObject = {
            TableName: "users",
            Key: { id: `${id}` },
            Item: {
                id: id,
                ...user
            }
        };
        documentClient.put(dataObject, function (err, data) {});

    }

    const updateUser = async () => {
        const user = localStorage.getItem('user')
        const userData = JSON.parse(user)
        if (userData.apps[app] && userData.apps[app][file]) {
            userData.apps[app][file][1] = userData.apps[app][file][1] + 1
        } else {
            userData.apps[app] = {
                ...userData.apps[app], [file]: [
                    Date.now(), 1
                ]
            }
        }
        localStorage.setItem('user', JSON.stringify(userData))


        const tableName = 'users';
        const partitionKey = 'id';
        const partitionValue = userData.uuid;
        const appKey = app;
        const appSubKey = file;
        const updateExpression = 'SET apps.#app.#appSubKey = :newValue';
        const expressionAttributeNames = {
            '#app': appKey,
            '#appSubKey': appSubKey
        };

        const expressionAttributeValues = {
            ':newValue': userData.apps.pdf[app]
        };

        const conditionExpression = 'attribute_exists(apps.#app.#appSubKey)';

        const updateParams = {
            TableName: tableName,
            Key: {
                [partitionKey]: partitionValue
            },
            UpdateExpression: updateExpression,
            ExpressionAttributeNames: expressionAttributeNames,
            ExpressionAttributeValues: expressionAttributeValues,
            ConditionExpression: conditionExpression,
            ReturnValues: 'ALL_NEW' // Optional, returns the updated item
        };
        documentClient.update(updateParams, (err, data) => {
            if (err) {
                creatSub(userData)
            }
        });
    }

    const creatSub = (userData) => {
        documentClient.get({
            TableName: 'users',
            Key: { id: `${userData.uuid}` }
        },(error,result)=>{
            if(!error){
                const currentData = result.Item;
                if(!currentData.apps[app]){
                    currentData.apps[app] = []
                }
                if(!currentData.apps[app][file]){
                    currentData.apps[app][file]= []
                }
                currentData.apps[app][file] = [
                    Date.now(), 1
                ]
                documentClient.put({
                    TableName: 'users',
                    Item: currentData
                },(err,res)=>{
                })
            }
        })
    }
}
