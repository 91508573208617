export const SET_PLAY = {}
export const SET_SOUND = {}
export const SET_PLAY_ICON = {}

export const setPlay = playAudio => dispatch => {
    dispatch({
        type: SET_PLAY,
        payload: playAudio,
    })
}
export const setSoundName = SoundName => dispatch => {
    dispatch({
        type: SET_SOUND,
        payload: SoundName,
    })
}

export const setPlayIcon = SoundName => dispatch => {
    dispatch({
        type: SET_PLAY_ICON,
        payload: SoundName,
    })
}

