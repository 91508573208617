import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { NavBar, Footer, DefinitionRender } from "../../components"
import configuration from "../../../package.json"

export const Definitions = (props) => {
  const {homepage} = configuration;
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [title, setTitle] = useState([])
  const [letters, setLetters] = useState([])

  useEffect(() => {
    if (location.state) {
      setList(location.state.items)
      setTitle(location.state.title)
    } else {
      navigate(`${homepage}`)
    }

    let letters_ = [];
    for (let i = 65; i <= 90; i++) {
      letters_.push(String.fromCharCode(i));
    }
    setLetters(letters_)
    console.log(list);
  }, [])

  return (
    <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2 overflow-hidden md:max-w-[875px] md:m-auto">
      <NavBar title={title.phrase} backto={-1}  search={true} />
      <div className="w-full flex p-2 top-20 relative">
        <div className="flex flex-col divide-y w-full  overflow-hidden rounded-lg">
          {
            letters.map((letter, index) => {
              const filteredList = (list).sort((a, b) => {
                return a.phrase.localeCompare(b.phrase);
              }).filter(item =>{
                if(item.phrase.replace(/[()]/g, '').toLowerCase().startsWith(letter.toLowerCase())){
                  return item.phrase
                }
              });
              if (filteredList.length === 0) {
                return <div key={index}></div>;
              }
              return (
                <>
                  <div key={index} className="flex w-3/12 text-center items-center justify-center leading-[25px] rounded-t-lg bg-gray-600 text-white self-center">
                    {letter.toLowerCase()}
                  </div>
                  <div className="bg-white flex flex-col divide-y w-full overflow-hidden rounded-lg mb-3">
                    {filteredList.map((item, ind) => (
                      <DefinitionRender key={ind} {...item} definition={true} />
                    ))}
                  </div>
                </>
              );
            })
          }
        </div>
      </div>
      <div className="h-24" />
      <Footer />
    </div>
  );
}
