import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App"
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Intro, Home, Vocabulary, Sections, Definitions, Collocations, Table, Derivatives } from './apps/dictionary';
import { Provider } from "react-redux";
import {store} from "./store"
import { Search } from './apps/dictionary/Search';


ReactDOM.createRoot(document.getElementById('root')).render(
  
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<App />} />
          <Route exact path="/dict/home" element={<Intro />} />

          {/* dictionary */}
            <Route exact path="/dict/:subject/:grade" element={<Home />} />
            <Route exact path="/dict/:subject/:grade/:year/:version" element={<Home />} />
            <Route exact path="/dict/:subject/:grade/search" element={<Search />} />
            <Route exact path="/dict/:subject/:grade/:year/:version/vocabulary" element={<Vocabulary />} />
            <Route exact path="/dict/:subject/:grade/:year/:version/sections" element={<Sections />} />
            <Route exact path="/dict/:subject/:grade/:year/:version/definitions" element={<Definitions />} />
            <Route exact path="/dict/:subject/:grade/:year/:version/collocations" element={<Collocations />} />
            <Route exact path="/dict/:subject/:grade/:year/:version/table" element={<Table />} />
            <Route exact path="/dict/:subject/:grade/:year/:version/derivatives" element={<Derivatives />} />
          {/* dictionary */}

          <Route exact path="/*" element={<Intro />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

