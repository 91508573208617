import React from 'react'
import confguration from "../../package.json";

export const NotFound = () => {

    return (
        <div className="w-full items-center justify-center flex flex-row z-50 sm:max-w-[875px] md:m-auto">
            <div className="flex w-full h-[70vh] justify-center items-center flex-col gap-7 ">
                <div className="relative flex flex-row justify-center items-center gap-3">
                    <div className="text-9xl font-extrabold">4</div>
                    <img alt='' src={confguration.homepage+"images/icon.svg"} className='h-20 w-20' />
                    <div className="text-9xl font-extrabold">4</div>
                </div>
                <div className="text-xl text--company-red">Not found...! </div>
            </div>
        </div>
    )
}
