import React, { useEffect, useState } from "react";
import { NavBar, Footer, AnalysisPDF } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import jsyaml from "js-yaml";
import configuration from "../../../package.json"

export const Intro = (props) => {
  const navigate = useNavigate();
  const {homepage} = configuration;
  const [items, setItems] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [settings, setSettings] = useState([])
  const [filesSize, setFilesSize] = useState([])
  const [downLoadFiles, setDownLoadFiles] = useState(null)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    navigate.replace(path);
  }

  useEffect(() => {
    getManifest();
  }, [props]);

  const getManifest = () => {
    axios
      .get(`${homepage}data/manifest.yaml`)
      .then((respons) => {
        const { manifest } = jsyaml.load(respons.data);
        setItems(manifest.items);
        const uniqueObjects = [];
        for (const item of manifest.items) {
          const {content,subject} = item;
          const isDuplicate = uniqueObjects.some(obj => obj.content === content && obj.subject === subject);
          if (!isDuplicate) {
            uniqueObjects.push({content:item.content,subject:item.subject});
          }
        }
        setSubjects(uniqueObjects)

      }).then(()=>{
        axios
          .get(`${homepage}data/settings.yaml`)
          .then((res) => {
            const data = jsyaml.load(res.data);
            setSettings(data.filter(e=>e.lang==='en'))
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getFileSizeFromLink = (link,ind) => {
    let mbSize = null;
    if(!filesSize[ind]){
      axios
      .get(link)
      .then( (res) => {
        mbSize = ((res.headers['content-length']/ 1024)/ 1024).toFixed(2)
        setFilesSize((filesSize)=>{
          const newArray = [...filesSize]
          newArray.splice(ind,1,mbSize)
          return newArray
        })
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  const PDFSizeRender = ({index,file}) => {
    getFileSizeFromLink(`/${file}`,index)
    return (<div className="flex flex-row gap-2 items-center">
      {
        filesSize[index]?<div className="text-[12px]">{filesSize[index]+' MB'}</div>:<>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 animate-spin">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>
        </>
      }
    </div>)

  }

  const DownloadPDF = async (link) => {
    if(downLoadFiles !== link){
      setDownLoadFiles(link)
        var newTab = window.open(homepage+link, '_blank');
        newTab.focus();
        setTimeout(() => {
          setDownLoadFiles(null)
        }, 4000);
    }
  }

  return (
    <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2  overflow-hidden md:max-w-[875px] md:m-auto relative">
      <NavBar
        title={settings[0]?settings[0].series_names.m:"GPS Education"}
        home={false}
        search={false}
      />
      <div className="grid w-full grid-cols-1 sm:grid-cols-2 px-2 top-16 mt-2 relative">
        {subjects.map((subjectItem, index) => (
          <div key={index} className="col-span-1">
            <div className="p-2 gap-4 flex flex-col sm:flex-row justify-around">
              <div className="w-full grid grid-cols-1 gap-3">
                <div className="col-span-1 flex w-full flex-col rounded-lg divide-y overflow-hidden">
                  <div className="bg-gray-700 rounded-t-lg text-white text-center p-1 text-xl text-md font-bold"> 
                    {settings[0]?settings[0].subject_titles[subjectItem.subject]:subjectItem.subject} {settings[0]?settings[0].content_names[subjectItem.content]:subjectItem.subject} 
                  </div>
                  {items
                    .filter((item) => item.content === subjectItem.content.toLowerCase() && item.subject === subjectItem.subject)
                    .map((item, ind) => (item.content === "dictionary")?(
                      <Link
                        key={ind}
                        to={`/dict/${item.subject.toLowerCase()}/${item.grade}`}
                        title={`Grade ${item.grade}`}
                        className="cursor-pointer text-md font-semibold p-1 px-3 items-center justify-center bg-white"
                      >
                        {
                          settings[0]?settings[0].grade_names[item.grade]:''
                        }
                      </Link>
                    ):<>
                      <div className="p-2 items-center justify-center bg-white grid grid-cols-4 gap-2">
                        <div className="w-full col-span-3 flex flex-col">
                          <div className="text-md leading-4 font-semibold p-1">
                            {
                              item.title
                            }
                          </div>
                          <div className="text-md leading-4 pl-1 ml-1 text-gray-500 border-l-8 border-gray-300">
                            {
                              ( settings[0]?settings[0].grade_names[item.grade]:'')
                            }
                          </div>
                        </div>
                        <div className="w-fit col-span-1 flex flex-col justify-center items-center ">
                          {
                            (downLoadFiles === item.pdf)?<AnalysisPDF app={'pdf'} file={item.pdf.replace('pdfs/','').replace('.pdf','')} />:<></>
                          }

                          <button
                            onClick={()=> DownloadPDF(item.pdf)}
                            className={`cursor-pointer py-1 px-3 leading-3 font-bold text-sm ${downLoadFiles === item.pdf?'text-blue-600 bg-blue-100 border-blue-600':'text-company-red bg-red-100 border-company-red'} border  items-center justify-center rounded-lg flex flex-row gap-2 w-fit shrink-0`}
                          >
                          {
                            (downLoadFiles !== item.pdf)?
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 leading-3">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                              </svg>
                            :<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 animate-spin leading-3">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                          }
                          </button>
                            {/* <PDFSizeRender index={ind} file={item.pdf} /> */}
                            <div className="text-[11px] leading-[20px] text-gray-500">{item.size}</div>
                        </div>
                      </div>
                    </>)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="h-24" />
      <Footer />
    </div>
  );
};

