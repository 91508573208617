import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { setPlay, setSoundName, setPlayIcon } from '../../store/actions';
import {SoundPlayer} from ".."

export const DefinitionRender = (props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const {index, phrase, sound, style, ar, suffix, unit, description} = props

    const { soundName, playIcon } = useSelector(state=>state.userAppReducer)

    const playSound = async (sound) => {
        const soundPlayer = new SoundPlayer(
          sound,
          dispatch,
          setSoundName,
          setPlay,
          setPlayIcon,
          params,
          dispatch.bind(this,setSoundName(null)),
          dispatch.bind(this,setPlayIcon(true)),
          dispatch.bind(this,setPlayIcon(false))
        );
        soundPlayer.playSound();
      };


      const HundelHtml = ({text}) => {
        const data = text?.phrase?.replace(/(\\r\\n|\\r|\\n)/g,'<br />').replace(/\\/g,'')
        return (<div className="text leading-5 cursor-pointer font-light" dangerouslySetInnerHTML={{__html: data}} />)
      }

    return (
      <div  onClick={() => playSound(description.sound?[sound,description.sound]:sound)} className="flex w-full flex-col p-2  hover:bg-slate-100 focus::bg-slate-100 overflow-hidden">
          <div className={`flex flex-row gap-2 leading-3 justify-start items-center ${(sound && sound !== "") ? 'cursor-pointer' : ''}`}>
            <div id={sound.replace('.wav', '')} className={`flex flex-row justify-start leading-3 -mt-2 gap-1`}>
              <div className={`flex pr-1 text-left ${(style == "red") ? 'text--company-red font-bold' : ''}`}>{phrase} </div>
              {
                (suffix) ? <div>{unit ? '(' : '['}{suffix}{unit ? ')' : ']'}</div> : <></>
              }
              {
                (unit) ? <div>[U{unit}]</div> : <></>
              }
              {
                (soundName == sound && playIcon == false)?
                  <div className="flex-auto text-right h-full justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mt-3 animate-spin">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>
                  </div>
                :<></>
              }
              {
                (soundName == sound && playIcon == true)?
                  <div className="flex-auto text-right h-full justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mt-3 ">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                    </svg>
                  </div>
                :<></>
              }
            </div>
            <div className="flex-auto text-right leading-3 -mt-2">
              <bdi className='font-serif'>{ar}</bdi>
            </div>
          </div>
          <HundelHtml text={description}/>
      </div>
      )

}
