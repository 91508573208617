import { SET_PLAY, SET_SOUND, SET_PLAY_ICON } from "./actions";

import IndexedDBManager from "../DB/indexDB";
import configure from "../../package.json";

const { homepage, version, indexedDB, soundPath} = configure;

const audioState = {
  playingAudio: false,
  soundName: null,
  playIcon: false
};
let audioCtx = new AudioContext();

// Function to convert audio file to base64
function convertAudioToBase64(url) {
  return fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    });
}

function playSoundAction(sound, action_1, action_2) {
  fetch(sound)
    .then((response) => response.arrayBuffer())
    .then((buffer) => audioCtx.decodeAudioData(buffer))
    .then((buffer) => {
      let track = audioCtx.createBufferSource();
      track.buffer = buffer;
      track.connect(audioCtx.destination);
      track.addEventListener("ended", () => {
        if (action_2[2]) {
          action_2[2]()
        }
        if (action_2[0]) {
          action_2[0]()
        }
        if (action_1) {
          setTimeout(() => {
            action_1()
          }, 300);
        } else {
          audioState.audioPlaying = false;
          return audioState;
        }
      });
      if (action_2[1]) {
        action_2[1]()
      }
      track.start(0);
    });
}

async function addToIndexedDB(sound, base64Data, app, grade) {
  const dbManager = new IndexedDBManager(indexedDB, "sounds", version);
  await dbManager
    .openDatabase()
    .then(async () => {
      await dbManager
        .addData({
          id: sound,
          data: base64Data
        })
        .then(async (result) => {})
        .catch((e) => {
          console.log(e);
        });
    })
    .catch((error) => {
      console.error(error);
    });
}


function userAppReducer(state = audioState, action) {
  switch (action.type) {
    case SET_PLAY:
      let audioLink = "";
      if (audioState.audioPlaying) {
        return audioState;
      }

      if (action.payload.isList) {
        const payload = action.payload
        setTimeout(() => {
          audioLink = soundPath + "sounds/" + payload.items[0].sound + ".wav";
          audioState.soundName = payload.items[0].sound;
          audioState.audioPlaying = true;
          const item = payload.items[0];
          if (item.base64 === false) {
            convertAudioToBase64(audioLink).then(async (base64Data) => {
              playSoundAction(base64Data, () => {
                audioLink = soundPath + "sounds/" + payload.items[1].sound + ".wav";
                audioState.audioPlaying = true;
                const item = payload.items[1];
                if (item.base64 == false) {
                  convertAudioToBase64(audioLink).then(async (base64Data) => {
                    playSoundAction(base64Data, null, action.payload.action)
                    await addToIndexedDB(item.sound, base64Data, payload.app, payload.grade)
                  });
                } else {
                  playSoundAction(item.sound, null, action.payload.action)
                }
              }, action.payload.action)
              await addToIndexedDB(item.sound, base64Data, payload.app, payload.grade)
            });
          } else {
            playSoundAction(item.sound, () => {

              audioLink = soundPath + "sounds/" + payload.items[1].sound + ".wav";

              audioState.audioPlaying = true;
              const item = payload.items[1];
              if (item.base64 === false) {
                convertAudioToBase64(audioLink).then(async (base64Data) => {
                  playSoundAction(base64Data)
                  await addToIndexedDB(item.sound, base64Data, payload.app, payload.grade)
                });
              } else {
                playSoundAction(item.sound, null, action.payload.action)
              }
            }, action.payload.action)
          }
        }, 50);

      } else {
        audioLink = soundPath + "sounds/" + action.payload.sound + ".wav";
        audioState.audioPlaying = true;
        if (action.payload.base64 === false) {
          convertAudioToBase64(audioLink).then(async (base64Data) => {
            playSoundAction(base64Data, null, action.payload.action)
            await addToIndexedDB(action.payload.sound, base64Data, action.payload.app, action.payload.grade)
          });
        } else {
          playSoundAction(action.payload.sound, null, action.payload.action)
        }

      }
      return state;
    case SET_SOUND:
      return {
        ...state,
        soundName: action.payload
      }
      case SET_PLAY_ICON:
        return {
          ...state,
          playIcon: action.payload
        }
        default:
          return state;
  }
}

export default userAppReducer;