import React, { useEffect, useState } from "react";
import { NavBar, Footer,  DefinitionRender, VocabularyRender } from "../../components";
import configuration from "../../../package.json";
import { useParams } from "react-router-dom";
import axios from "axios";
import jsyaml from "js-yaml";
import { v4 as uid4 } from "uuid";

export const Search = (props) => {
  const { homepage } = configuration;
  const params = useParams();
  const [startSearch, setStartSearch] = useState(false);
  const [word, setWord] = useState(null);
  const [searshList, setSearshList] = useState([]);
  const [parts, setParts] = useState([]);

  useEffect(() => {
      axios
        .get(`${homepage}data/manifest.yaml`)
        .then((res) => {
          const { manifest } = jsyaml.load(res.data);
          const curentData = manifest.items.filter(
            (item) =>
              item.subject == params.subject &&
              item.grade == params.grade &&
              item.content == "dictionary"
          );
          console.log(params.version);
          if (curentData.length > 0) {
            axios
              .get(
                `${homepage}data/${curentData[curentData.length - 1].yaml}.yaml`
              )
              .then((res) => {
                const parsedData = jsyaml.load(res.data);
                setParts(parsedData.parts);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  }, []);


  const searchInObject = (word) => {
    let words = [];
    const list = [];
    setWord(word);
    if (word && word.length > 0) {
      setStartSearch(true);
      parts.forEach((part, index) => {
        if (part.kind === "vocabulary") {
          part.items.forEach((item, ind) => {
            if (checkText(item.phrase, word)) {
              words.push({ ...item, kind: part.kind });
            }
          });
        } else if (part.kind === "definitions") {
          part.items.forEach((item, ind) => {
            if (checkText(item.phrase, word)) {
              words.push({ ...item, kind: part.kind });
            }
          });
        } else if (part.kind === "sections") {
          part.sections?.map((section, indSection) => {
            if (section.kind === "vocabulary") {
              section.items.forEach((item, ind) => {
                if (checkText(item.phrase, word)) {
                  words.push({ ...item, kind: section.kind });
                }
              });
            } else if (section.kind === "definitions") {
              section.items.forEach((item, ind) => {
                if (checkText(item.phrase, word)) {
                  words.push({ ...item, kind: section.kind });
                }
              });
            } else if (section.kind === "collocations") {
              section.items.forEach((item, ind) => {
                item.words?.map((e, ind) => {
                  if (checkText(e.phrase, word)) {
                    words.push({
                      ...e,
                      kind: section.kind,
                      header: item.phrase,
                    });
                  }
                });
              });
            } else if (section.kind === "collocations") {
              section.items.forEach((item, ind) => {
                if (checkText(item.phrase, word)) {
                  words.push({ ...item, kind: section.kind });
                }
              });
            } else if (section.kind === "table") {
              section.items.forEach((item, ind) => {
                if (
                  checkText(item.word.phrase, word) ||
                  checkText(item.alt.phrase, word)
                ) {
                  words.push({
                    ...item,
                    kind: section.kind,
                    header: { ...section.header[0], id: uid4() },
                  });
                  if (
                    !list.filter(
                      (word) =>
                        word.header?.alt?.phrase == section.header[0].alt.phrase
                    ).length > 0
                  ) {
                    list.push({
                      header: section.header[0],
                      items: [],
                    });
                  }
                }
              });
            }
          });
        }
      });

      list.map((li) => {
        let a = words.filter(
          (word) =>
            word.kind == "table" &&
            word.header.alt.phrase === li.header.alt.phrase
        );
        li.items = a;
      });
      words = words.filter((word) => word.kind !== "table");
      words.push({
        kind: "table",
        items: list,
      });
      setSearshList(words);
    } else {
      setWord("");
      setSearshList([]);
      setStartSearch(false);
    }
  };

  const checkText = (phrase, word) => {
    return phrase
      .replace(/[()]/g, "")
      .toLowerCase()
      .startsWith(word.toLowerCase());
  };

  const Columns = ({ alt, word, id }) => {
    return (
        <div
            id={id}
            className={`headerTable flex leading-5 flex-row border border-gray-500  w-full  overflow-hidden`}
        >
            <div className="flex w-full leading-5 col-span-1 text-center items-center self-center justify-center">
                {word.phrase} {word.ar}
            </div>
            <div className="flex w-full leading-5 col-span-1 text-center items-center self-center justify-center">
                {alt.phrase} {alt.ar}
            </div>
        </div>
    );
  };

  const SearchResultRender = ({ items, item, index }) => {
    let vocabularyLength = items.filter(
      (item) => item.kind === "vocabulary"
    ).length;
    let definitionsLength = items.filter(
      (item) => item.kind === "definitions"
    ).length;
    let tableLength = items.filter((item) => item.kind === "table").length;
    let collocationsLength = items.filter(
      (item) => item.kind === "collocations"
    ).length;

    if (item.kind === "vocabulary") {
      return (
        <>
          <VocabularyRender key={index} index={index} {...item} />
          {vocabularyLength - 1 === index ? (
            <></>
          ) : (
            <></>
          )}
        </>
      );
    } else if (item.kind === "definitions") {
      return (
        <>
          <DefinitionRender key={index} index={index} {...item} />
          {definitionsLength ===
          index - (vocabularyLength + tableLength) + 1 ? (
            <></>
          ) : (
            <></>
          )}
        </>
      );
    } else if (item.kind === "collocations") {
      return (
        <>
          <div key={index} className="bg-white overflow-hidden">
            <div className="bg-orange-300 text-center self-center flex-col w-full flex items-center">
              {item.header}
            </div>
            <VocabularyRender key={index} index={index} {...item} />
          </div>
          {collocationsLength - 1 === index ? (
            <></>
          ) : (
            <></>
          )}
        </>
      );
    } else if (item.kind === "table") {
      return (
        <>
          <div
            className={`flex w-full flex-col justify-stretch self-end divide-y relative`}
          >
            {item.items.map((ite, index) => (
              <>
                <Columns {...ite.header} />
                {ite.items.map((it, id) => (
                  <>
                    <div className="flex flex-col border border-gray-400 overflow-hidden items-stretch">
                      <div
                        key={uid4()}
                        className={`flex flex-row odd:bg-gray-100 even:bg-white w-full items-stretch`}
                      >
                        <VocabularyRender index={id} {...it.word} />
                        <VocabularyRender index={id} {...it.alt} />
                      </div>
                    </div>
                  </>
                ))}
              </>
            ))}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2  overflow-hidden md:max-w-[875px] md:m-auto relative">
      <NavBar
        logo="logo_3.svg"
        home={false}
        grade="3"
        extention="rd"
        main="Prep."
        search={false}
      />
      <div
        className={`flex-row gap-2 items-center w-full flex relative top-[60px] bg-white p-2`}
      >
        <div>Search: </div>
        <input
          id="searchInput"
          name="word"
          onChange={(e) => {
            searchInObject(e.target.value);
          }}
          className="flex w-full px-2 py-0 border rounded-full"
          placeholder="type a word ... "
        />
          <svg
            onClick={() => {
                window.history.back();
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
      </div>
      <div className="flex flex-col w-full top-[60px] relative">
      {startSearch ? (
          <div className="flex w-full flex-col gap-3">
            <div className="flex flex-col">
              <div className="bg-white flex flex-col divide-y w-full  overflow-hidden rounded-lg">
              {searshList.sort((a, b) => {
                return b.kind.localeCompare(a.kind);
              }).map((item,index)=><SearchResultRender key={index} index={index} items={searshList} item={item}/>)}
              </div>
              <div className="h-24" />
            </div>
          </div>
      ) : (
        <></>
      )}
      </div>
      <div className="h-24" />
      <Footer />
    </div>
  );
};
