import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { NavBar, Footer } from "../../components"
import configuration from "../../../package.json"



export const Sections = (props) => {
  const params = useParams()
  const {homepage} = configuration;
  const localtion = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [title, setTitle] = useState([])

  useEffect(() => {
    if (localtion.state) {
      setList(localtion.state.sections)
      setTitle(localtion.state.title)
    } else {
      navigate(`${homepage}`)
    }
  }, [])

  const SectionRender = ({ index, title, kind, discreption, items, header, lettersort }) => {
    // console.log(lettersort);
    return (<>
      <div className={`flex w-full flex-col justify-between shadow-lg ${(title.sound && title.sound !== "") ? 'cursor-pointer' : ''}`}>
        <div className="flex flex-row">
          <div className="flex w-full flex-row justify-start items-stretch gap-1">
            <div className='bg-gray-600 text-white w-10 text-center rounded-l-lg shrink-0 self-stretch relative'>
              <div className='absolute w-full h-full top-0 bottom-0 m-auto'>{index}</div>
            </div>
            <div className='bg-white text-black w-full h-fit rounded-r-lg flex flex-row gap-1 justify-between'>
              <Link
              to={`/dict/${params.subject}/${params.grade}/${params.year}/${params.version}/${kind}`} 
              hash={title} 
              state={{ title, kind, items, header, lettersort }}
              className="flex flex-row flex-wrap justify-between w-full">
                <div className="flex-auto px-2">{title.phrase}</div>
                <div className="flex-auto px-2 text-right">{title.ar}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
    )
  }


  return (
    <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2 overflow-hidden md:max-w-[875px] md:m-auto">
      <NavBar title={title.phrase} backto={-1}  search={true} />
      <div className="w-full flex p-2 top-20 relative">
        <div className="flex w-full flex-col gap-2 justify-start">
          {
            list.map((item, index) => <SectionRender key={index} index={index + 1} {...item} />)
          }
        </div>
      </div>

      <div className="h-24" />
      <Footer />
    </div>
  );
}
