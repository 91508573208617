export default class IndexedDBManager {
  constructor(databaseName, table, version) {
      this.databaseName = databaseName;
      this.table = table;
      this.version = version;
      this.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
      this.db = null;
  }

  openDatabase() {
      return new Promise((resolve, reject) => {
          const request = this.indexedDB.open(this.databaseName, this.version);

          request.onerror = (event) => {
              reject("Error opening database");
          };

          request.onsuccess = (event) => {
              this.db = event.target.result;
              resolve();
          };

          request.onupgradeneeded = (event) => {
              this.db = event.target.result;
              this.createStoreName();
          };
      });
  }

  createStoreName() {
    ['user','sounds','images'].map(table=> {
      if(!this.db.objectStoreNames.contains(table)){
          try {
              this.db.createObjectStore(table);
          } catch (error) {
          }
      }
    })
  }

  addData(object) {
      return new Promise((resolve, reject) => {
          const transaction = this.db.transaction(this.table, "readwrite");
          const store = transaction.objectStore(this.table);
          const request = store.get(object.id);
          request.onsuccess = (event) => {
              if (request.result) {
                  const updateRequest = store.put(object.data, object.id);
                  updateRequest.onsuccess = () => {
                      resolve();
                  };
                  updateRequest.onerror = () => {
                      reject("Error updating data");
                  };
              } else {
                  const addRequest = store.add(object.data, object.id);
                  addRequest.onsuccess = () => {
                      resolve();
                  };

                  addRequest.onerror = () => {
                      reject("Error adding data");
                  };
              }
          };

          request.onerror = () => {
              reject("Error finding data");
          };
      });
  }

  updateData(object) {
      return new Promise((resolve, reject) => {
          const transaction = this.db.transaction([this.table], "readwrite");
          const store = transaction.objectStore(this.table);
          const request = store.put(object.data, object.id);

          request.onerror = (event) => {
              reject("Error updating data");
          };
          request.onsuccess = (event) => {
              resolve();
          };
      });
  }

  getData(key) {
      return new Promise((resolve, reject) => {
          const transaction = this.db.transaction([this.table], "readonly");
          const store = transaction.objectStore(this.table);
          const request = store.get(key);
          request.onerror = (event) => {
              reject("Error getting data");
          };
          request.onsuccess = (event) => {
              resolve(request.result);
          };
      });
  }

  batchDelete(keys) {
      return new Promise((resolve, reject) => {
          const transaction = this.db.transaction([this.table], "readwrite");
          const store = transaction.objectStore(this.table);
          var request;
          keys.map((key) => {
              request = store.delete(key);
          })
          request.onerror = (event) => {
              reject("Error getting key");
          };

          request.onsuccess = (event) => {
              resolve(request.result);
          };
      });
  }
}