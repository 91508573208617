import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { NavBar, Footer, VocabularyRender } from "../../components"
import configuration from "../../../package.json"

export const Vocabulary = (props) => {
  const {homepage} = configuration;
  const localtion = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [title, setTitle] = useState([])
  const [letters, setLetters] = useState([])
  const [letterSort, setLetterSort] = useState(true)
  const [haseImages, setHasImages] = useState(true)


  useEffect(() => {
    if (localtion.state) {
      setList(localtion.state.items)
      setTitle(localtion.state.title)
      setLetterSort(localtion.state.lettersort)
      setHasImages(localtion.state.images)
      // console.log();
    } else {
      navigate(`${homepage}`)
    }
    let letters_ = [];
    for (let i = 65; i <= 90; i++) {
      letters_.push(String.fromCharCode(i));
    }
    setLetters(letters_)
  }, [])

  return (
    <div className="flex w-full max-w-screen min-h-screen p-0 m-0 flex-col gap-2 overflow-hidden md:max-w-[875px] md:m-auto">
      <NavBar title={title.phrase} backto={-1}  search={true} />
      <div className="w-full flex flex-col p-0 top-16 relative">
        {
          title.subtitle?
          <div className="flex w-full mb-5 text-center items-center justify-center rounded-b-lg text-white bg-gray-600 self-center py-3 text-2xl font-bold">
              {title.subtitle}
          </div>
          :
          <div className="flex w-full mb-5 text-center items-center justify-center rounded-b-lg text-white self-center py-3 text-2xl font-bold"></div>
        }
        {
          haseImages===true?<>
            <div  className="flex flex-row flex-wrap justify-center gap-5 px-2">
              {
                list.map((item, ind) => (
                    <VocabularyRender images={true} key={ind} index={ind} {...item} />
                ))
              }
            </div>

          </>
        :
          letters.map((letter, index) => {
            const filteredList = list.filter(item =>{
              if(item.phrase.replace(/[()]/g, '').toLowerCase().startsWith(letter.toLowerCase())){
                return item.phrase
              }
            }
            );
            if (filteredList.length === 0) {
              return <></>;
            }
            return (
              <div id={index} key={index} className="flex flex-col justify-center px-2">

              {
                letterSort==false?
                <></>
                :<div className="flex w-3/12 text-center items-center justify-center leading-[25px] rounded-t-lg bg-gray-600 text-white self-center">
                  {letter.toLowerCase()}
                </div>
              }
                <div className="bg-white flex flex-col divide-y w-full overflow-hidden rounded-lg mb-3">
                  {filteredList.map((item, ind) => (
                    <VocabularyRender key={ind} index={ind} {...item} />
                  ))}
                </div>
              </div>
            );
          })
        }
      </div>
      <div className="h-24" />
      <Footer />
    </div>
  );
}
