import React from 'react'

export const Footer = (props) => {
    const {logos} = props
    return (
        <div className="w-full items-center justify-between flex flex-col z-50 sm:max-w-[875px] md:m-auto py-2 mt-10">
            {
                (logos==true)?<div className='flex flex-row justify-center'>
                <img
                src="/logos/logo_3.svg"
                alt="الإمتحان"
                className="h-5 w-auto px-4"
                />
                <img
                src="/logos/logo_1.svg"
                alt="المعاصر"
                className="h-5 w-auto px-4"
                />
                <img
                src="/logos/logo_2.svg"
                alt="EL-Moasser"
                className="h-5 w-auto px-4"
                />
            </div>:<></>
            }
        </div>
    )
}
