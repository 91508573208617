import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { setPlay, setSoundName, setPlayIcon } from '../../store/actions';
import {SoundPlayer} from "../"

export const VocabularyRender = (props) => {
    const dispatch = useDispatch()
    const params = useParams()
    const {index, phrase, sound, style, ar, suffix, unit, collocation, images, image} = props
    const { soundName, playIcon } = useSelector(state=>state.userAppReducer)
  console.log("sound",sound)
    const playSound = async (sound) => {
        const soundPlayer = new SoundPlayer(
          sound,
          dispatch,
          setSoundName,
          setPlay,
          setPlayIcon,
          params,
          dispatch.bind(this,setSoundName(null)),
          dispatch.bind(this,setPlayIcon(true)),
          dispatch.bind(this,setPlayIcon(false))
        );
        soundPlayer.playSound();
      };

      if(images){
        const link = image+"".toLowerCase();
        return (
          <div onClick={() => playSound(collocation?sound:sound)} key={"-"+index} className='flex w-[150px] relative flex-col flex-center items-center gap-2 p-1 rounded-lg bg-white shadow-sm'>
            <img src={`/assets/${link}.webp`} alt={phrase} className='w-auto h-20'  title={phrase} />
            <div id={sound.replace('.wav', '')} className={`flex flex-row justify-start gap-1`}>
                <div className={`px-0 text-center leading-5 ${(style === "red") ? 'text-company-red font-bold' : ''}`}>{phrase} </div>
                {
                  (suffix) ? <div>{unit ? '(' : '['}{suffix}{unit ? ')' : ']'}</div> : <></>
                }
                {
                  (unit) ? <div>[U{unit}]</div> : <></>
                }
                {
                  (soundName === sound && playIcon === false)?
                  <div className="flex-auto text-right  justify-center absolute bg-gray-100 rounded-full p-2 -top-2 -right-2 shadow-md h-fit w-fit items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 animate-spin">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                    </div>
                  :<></>
                }
                {
                  (soundName === sound && playIcon === true)?
                  <div className="flex-auto text-right  justify-center absolute bg-gray-100 rounded-full p-2 -top-2 -right-2 shadow-md h-fit w-fit items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                      </svg>
                    </div>
                  :<></>
                }
              </div>
          </div>
          )
      }else{
        return (
            <div id={index} onClick={() => playSound(collocation?sound:sound)} key={"-"+index} className={` ${(sound && sound !== "") ? 'cursor-pointer' : ''} flex flex-row  hover:bg-slate-100 focus::bg-slate-100 flex-wrap w-full border item-center`}>
              <div id={sound?.replace('.wav', '')} className={`flex flex-row justify-start`}>
                <div className={`px-2 text-left ${(style === "red") ? 'text-company-red font-bold' : ''}`}>{phrase} </div>
                {
                  (suffix) ? <div>{unit ? '(' : '['}{suffix}{unit ? ')' : ']'}</div> : <></>
                }
                {
                  (unit) ? <div>[U{unit}]</div> : <></>
                }
                {
                  (soundName === sound && playIcon === false)?
                    <div className="flex-auto text-right h-full justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mt-3 animate-spin">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                    </div>
                  :<></>
                }
                {
                  (soundName === sound && playIcon === true)?
                    <div className="flex-auto text-right h-full justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mt-3 ">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
                      </svg>
                    </div>
                  :<></>
                }
              </div>
              <bdi className="flex-auto text-right flex-row px-2">
                {ar}
              </bdi>
            </div>
          )
      }

}
