import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DefinitionRender, VocabularyRender } from "./";
import axios from "axios";
import jsyaml from "js-yaml";
import $ from "jquery";
import configuration from "../../package.json";
import {v4 as uid4 } from 'uuid';
import { Link } from "react-router-dom";


export const NavBar = (props) => {
  const [tableHeader, setTableHeader] = useState('')
  const divRef = useRef(null);
  const {homepage} = configuration;
  const params = useParams()
  const { title, backto, search, home, grade, extention, main, logo, fullWidth } = props;
  const navigate = useNavigate();
  const [startSearch, setStartSearch] = useState(false);
  const [word, setWord] = useState(null);
  const [searshList, setSearshList] = useState([]);
  const [parts, setParts] = useState([]);
  const [searchBar, setSearchBar] = useState(false);
  const [tableItems, setTableItems] = useState([])
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    if (search) {
      axios.get(`${homepage}data/manifest.yaml`).then((res) => {
        const { manifest } = jsyaml.load(res.data);
        const curentData = manifest.items.filter(item => item.subject == params.subject && item.grade == params.grade && item.content == "dictionary")
        console.log(params.version);
        if (curentData.length > 0) {
          axios.get(`${homepage}data/${curentData[curentData.length - 1].yaml}.yaml`).then((res) => {
            const parsedData = jsyaml.load(res.data);
            setParts(parsedData.parts);
          })
            .catch((error) => {
              console.log(error);
            });
        }
      }).catch((error) => {
        console.log(error)
      })
    }

  }, []);

  useEffect(() => {
    if (divRef.current) {
      const heightInPixels = divRef.current.clientHeight;
      const heightInVh = (heightInPixels / window.innerHeight) * 100;
      setDivHeight(heightInVh);
    }
  }, [searchBar]);

  const searchInObject = (word) => {
    let words = []
    const list = []
    setWord(word);
    if (word && word.length > 0) {
      setStartSearch(true);
      parts.forEach((part, index) => {
        if (part.kind === "vocabulary") {
          part.items.forEach((item, ind) => {
            if (checkText(item.phrase, word)) {
              words.push({...item,kind:part.kind})
            }
          });
        } else if (part.kind === "definitions") {

          part.items.forEach((item, ind) => {
            if (
              checkText(item.phrase, word)
            ) {
              words.push({...item,kind:part.kind})
            }
          });
        } else if (part.kind === "sections") {
          part.sections.map((section, indSection) => {
            if (section.kind === "vocabulary") {
              section.items.forEach((item, ind) => {
                if (checkText(item.phrase, word)) {
                  words.push({...item,kind:section.kind})
                }
              });
            }else if(section.kind === "definitions"){
              section.items.forEach((item, ind) => {
                if (checkText(item.phrase, word)) {
                  words.push({...item,kind:section.kind})
                }
              });
            } else if(section.kind === "collocations"){
              section.items.forEach((item, ind) => {
                item.words?.map((e,ind)=>{
                  if (checkText(e.phrase, word)) {
                    words.push({...e,kind:section.kind,header:item.phrase})
                  }
                })
              });
            }  else if(section.kind === "collocations"){

              section.items.forEach((item, ind) => {
                if (checkText(item.phrase, word)) {
                  words.push({...item,kind:section.kind,})
                }
              });

            } else if(section.kind === "table"){

              section.items.forEach((item, ind) => {
                if (checkText(item.word.phrase, word) || checkText(item.alt.phrase, word)) {
                  words.push({...item,kind:section.kind,header:{...section.header[0],id:uid4()}})
                  if(!list.filter(word=>word.header?.alt?.phrase==section.header[0].alt.phrase).length>0){
                    list.push({
                      header: section.header[0],
                      items:[]
                    })
                  }
                }
              });
            } else {

              
            }
          });
        }
      });

      list.map((li)=>{
        let a = words.filter(word=>word.kind=="table" && word.header.alt.phrase===li.header.alt.phrase)
        li.items = a
      })
      words = words.filter(word=>word.kind!=="table")
      words.push({
        kind: "table",
        items: list
      })
      setSearshList(words);
    } else {
      setWord("");
      setSearshList([]);
      setStartSearch(false);
    }
  };

  const checkText = (phrase, word) => {
      return phrase.replace(/[()]/g, '').toLowerCase().startsWith(word.toLowerCase())
  };

  const Columns = ({alt,word,id}) => {
    return (
      <div id={id} className={`headerTable bg-gray-300 flex leading-5 flex-row border border-gray-500  w-full  overflow-hidden`}>
        <div  className='flex w-full leading-5 col-span-1 text-center items-center self-center justify-center'>
          {word.phrase} {word.ar}
        </div>
        <div className='flex w-full leading-5 col-span-1 text-center items-center self-center justify-center'>
          {alt.phrase} {alt.ar}
        </div>
      </div>
    )
  }


const SearchResultRender = ({items,item,index}) => {
  let vocabularyLength = items.filter(item => item.kind === 'vocabulary').length;
  let definitionsLength = items.filter(item => item.kind === 'definitions').length;
  let tableLength = items.filter(item => item.kind === 'table').length;
  let collocationsLength = items.filter(item => item.kind === 'collocations').length;

  if(item.kind === "vocabulary"){
    return <>
      <VocabularyRender key={index} index={index} {...item} />
        {(vocabularyLength-1===index)?<div className="h-6 w-full bg-slate-300" ></div>:<></>}
      </>
  }else if(item.kind === "definitions"){
    return <>
      <DefinitionRender key={index} index={index} {...item} />
      {(definitionsLength===(index-(vocabularyLength+tableLength)+1))?<div className="h-6 w-full bg-slate-300" ></div>:<></>}
    </>
  }else if(item.kind === "collocations"){
    return <>
        <div key={index} className="bg-white overflow-hidden">
          <div className='bg-orange-300 text-center self-center flex-col w-full flex items-center'>
              {item.header}
          </div>
          <VocabularyRender key={index} index={index} {...item} />
        </div>
        {(collocationsLength-1===index)?<div className="h-5 w-full bg-slate-300" ></div>:<></>}
    </>
  }else if(item.kind === "table"){

    return(<>
<div className={`flex w-full flex-col justify-stretch self-end divide-y relative`}>
        {
          item.items.map((ite,index)=><>
            <Columns {...ite.header} />
            {
              ite.items.map((it,id)=><>
              <div className="flex flex-col border border-gray-400 overflow-hidden items-stretch">
                <div key={uid4()} className={`flex flex-row odd:bg-gray-100 even:bg-white w-full items-stretch`}>
                    <VocabularyRender index={id} {...it.word} />
                    <VocabularyRender index={id} {...it.alt} />
                </div>
              </div>
              </>)
            }
          </>)
        }
      </div>
      <div className="h-5 w-full bg-slate-300" ></div>
    </>)
  }else{
    return<></>
  }
}

  return (
    <div  ref={divRef} id="navbar" className={`w-full items-center justify-start sm:justify-between divide-y sm:divide-y-0 gap-1 flex flex-col sm:flex-row bg-white p-3 fixed z-50 ${(fullWidth==true)?'':'sm:max-w-[875px]'} md:m-auto border-b`}>
      <div className="items-center justify-between gap-2 w-full sm:w-fit shrink-0 flex flex-row">
        <div className="items-center w-full justify-start gap-2 sm:w-fit flex flex-row">
          {backto ? (
            <svg
              onClick={() => {
                navigate(backto);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer rotate-180"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          ) : (
            <></>
          )}
          <div
            className={`${!backto ? "flex text-center w-full " : "justify-center "
              }items-center flex w-full flex-row gap-2`}
          >
            <img alt="GPS" src={logo ? homepage+"bg/" + logo : homepage+"images/icon.svg"} className={logo ? 'w-auto h-8' : 'w-10 h-10'} />
            <div className="capitalize leading-5">{title}</div>
          </div>
        </div>
        {
          (search) ?
          <>
            {/* <button className="" onClick={() => {
              setSearchBar(!searchBar)
            }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 sm:hidden"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </button> */}
            <Link to={`/dict/${params.subject}/${params.grade}/search`}
                className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 sm:hidden"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
          </Link>
          </>
            : <></>
        }
      </div>
      <div className="w-14 shrink-0 hidden sm:flex" />
      {
        search ?
          <div
            className={`flex-row gap-2 items-center w-full pt-0 sm:pt-0 hidden sm:flex`}
          >
            <div>Search: </div>
            <input
              id="searchInput"
              name="word"
              onChange={(e) => {
                searchInObject(e.target.value);
              }}
              className="flex w-full px-2 py-0 border rounded-full"
              placeholder="type a word ... "
            />
            {word != null ? (
              <svg
                onClick={() => {
                  $("#searchInput").val("");
                  setSearchBar(false);
                  setStartSearch(false);
                  setWord(null);
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            ) : (
              <></>
            )}
          </div>
          : <></>
      }

      {searchBar ? (
        <div className={`flex-row gap-2 items-center w-full mt-2 pt-2 sm:pt-0 flex`}>
          <div>Search: </div>
          <input
            autoFocus={true}
            name="word"
            onChange={(e) => {
              searchInObject(e.target.value);
            }}
            className="flex w-full px-2 py-0 border rounded-full"
            placeholder="type a word ... "
          />
          <svg
            onClick={() => {
              setSearchBar(false);
              setStartSearch(false);
              setTableHeader('')
              setTableItems([])
              setWord(null);
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-8 h-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      ) : (
        <></>
      )}

      {startSearch ? (
        <div style={{top:divHeight+"vh", height:(100-divHeight)+"vh"}} className={`w-full md:max-w-[875px] md:m-auto bg-slate-300 z-50 absolute left-0 sm:top-16 mt-0 sm:mt-2 flex flex-col gap-2 p-2 overflow-auto`}>
          <div className="flex w-full flex-col gap-3">
            <div className="flex flex-col">
              <div className="bg-white flex flex-col divide-y w-full  overflow-hidden rounded-lg">
              {searshList.sort((a, b) => {
                return b.kind.localeCompare(a.kind);
              }).map((item,index)=><SearchResultRender key={index} index={index} items={searshList} item={item}/>)}
              </div>
              <div className="h-24" />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
