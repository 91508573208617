import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  NavBar,
  Footer,
  SoundPlayer,
  VocabularyRender,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { setPlay, setSoundName, setPlayIcon } from "../../store/actions";
import configuration from "../../../package.json";
import { v4 as uid4 } from "uuid";

export const Derivatives = (props) => {
  const { homepage } = configuration;
  const params = useParams();
  const dispatch = useDispatch();
  const localtion = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [header, setHeader] = useState([]);
  const [columnsList, setColumnsList] = useState([]);
  const [title, setTitle] = useState([]);
  // console.log(localtion.state);

  useEffect(() => {
    if (localtion.state) {
      setList(localtion.state.items || []);
      setColumnsList(localtion.state.header || []);
      setTitle(localtion.state.title);
      const sortedProps = Object.fromEntries(
        Object.entries(localtion.state.header).sort(([, a], [, b]) => a.sort - b.sort)
      )
      const keys = Object.keys(sortedProps);
      setHeader(keys)
    } else {
      navigate(`${homepage}`);
    }
  }, []);

  const playSound = async (sound) => {
    const soundPlayer = new SoundPlayer(
      sound,
      dispatch,
      setSoundName,
      setPlay,
      setPlayIcon,
      params,
      dispatch.bind(this, setSoundName(null)),
      dispatch.bind(this, setPlayIcon(true)),
      dispatch.bind(this, setPlayIcon(false))
    );
    soundPlayer.playSound();
  };

  const Columns = (props) => {
    const sortedProps = Object.fromEntries(
      Object.entries(props).sort(([, a], [, b]) => a.sort - b.sort)
    )
    const keys = Object.keys(sortedProps);
    // setHeader(keys)
    // console.log(keys , props, sortedProps);
    return (
      <div
        className={`bg-gray-300 flex flex-row border border-gray-500  w-full  overflow-hidden rounded-t-lg`}
      >
        {keys.map((item, index) => (
          <div className="flex w-full col-span-1 text-center items-center self-center justify-center">
            {props[item].phrase} {props[item].ar}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2 overflow-hidden md:max-w-[875px] md:m-auto">
      <NavBar title={title.phrase} backto={-1} search={true} />
      <div
        className={`flex w-full mb-2 flex-col justify-stretch border rounded-lg self-end divide-y p-2 top-20 relative`}
      >
        <Columns {...localtion.state.header} />
        <div className="flex flex-col border border-gray-400 overflow-hidden rounded-b-lg items-stretch">
          {list.map((item, index) => (
            <div
              key={uid4()}
              className={`flex flex-row odd:bg-gray-100 even:bg-white w-full items-stretch`}
            >
              {/* {console.log(item.group[0],header)} */}
              {
                header?.map((key, ind) => <VocabularyRender key={uid4()} index={ind} {...item.group[0][key]} />)
              }
              {/* {
                item.map((word, ind) => <VocabularyRender key={uid4()} index={ind} {...item.word} />)
              } */}

              {/* <VocabularyRender key={uid4()} index={index} {...item.word} />
                  <VocabularyRender key={uid4()} index={index}  {...item.alt} /> */}
            </div>
          ))}
        </div>
      </div>
      <div className="h-24" />
      <Footer />
    </div>
  );
};
