import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";
import { NavBar, Footer, VocabularyRender } from "../../components"

import configuration from "../../../package.json"

export const Collocations = (props) => {
  const {homepage} = configuration;
  const localtion = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [title, setTitle] = useState([])
  
  useEffect(() => {
    if (localtion.state) {
      setList(localtion.state.items || [])
      setTitle(localtion.state.title)
    } else {
      navigate(`${homepage}`)
    }
  }, [])


  return (
    <div className="flex w-full max-w-screen h-fit min-h-screen p-0 m-0 flex-col gap-2 overflow-hidden md:max-w-[875px] md:m-auto">
      <NavBar title={title.phrase} backto={-1} search={true} />
      <div className={`flex w-full mb-2 flex-col gap-3 border rounded-lg overflow-hidden self-end divide-y p-2  top-20 relative`}>
        {
          list.map((item, index) => <>
            <div key={index} className="bg-white divide-y rounded-lg overflow-hidden">
              <div className='bg-orange-300 text-center self-center flex-col w-full flex items-center cursor-pointer'>
                {item.phrase}
              </div>
              {
                item.words.map((word, ind) =><VocabularyRender key={ind} {...word} collocation={item.sound} />)
              }
            </div>
          </>)
        }
      </div>
      <div className="h-24" />
      <Footer />
    </div>

  );
}